// NRW

export default {
  settings: {
    disableIframePostMessage: false,
    ctaWhiteBg: "true",
    disableDocsFilter: true,
  },
  content: {
    general: {
      clientName: "NRW Holdings",
    },
    superFund: `
      <div class="section-title mt-5">
        <h2>
          MLC MasterKey Business Super with your employer
        </h2>
      </div>
      <div class="row content">
        <div class="col-lg-6">
          <p>
            By joining the company Superannuation fund, you benefit from group discount rates on fees and insurances. This means you pay much less than you would pay in a personal or individual plan.
          </p>
          <p>
            This website provides all the information you need around the superannuation fund your employer offers. There are helpful super tools and calculators, and information on other services we offer if you require additional support with your financial well-being.
          </p>
          <p>
            To nominate your superannuation fund with your employer, please scroll down to the choice form that can be located under Documents and Forms, found below.
          </p>
          <p><b>Member Fees</b></p>
          <ul>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Member Fee:
              <b>$24 p.a.</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Administration Fee:
              <b>0.09% p.a.</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Trustee Levy:
              <b>0.02% p.a.</b>
            </li>
          </ul>
          <p><b>Investment Fees</b></p>
          <ul>
            <li>
              <i class="ri-money-dollar-circle-line"></i> MySuper Investment Fees and Costs:
              <b>0.76% p.a.</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Transaction Costs:
              <b>0.05% p.a.</b>
            </li>
          </ul>
          <p>
            Investment fees and related costs are deducted from investment returns prior to calculation of daily unit prices. For full fee disclosure (including any amounts deducted from reserves) refer to the Fund Product Disclosure Statement (PDS).
          </p>
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p>
            <b>Insurances</b>
          </p>
          <p>
            <b>Death & Total Permanent Disability Cover</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <b>Salaried Staff</b>: Cover is based on your salary, age and years to age 65
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <b>Employee Agreement Workers</b>: Cover is based on your age
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <b>Casual Employees</b>: Cover is based on your age
            </li>
          </ul>

          <p>
            <b>Income Protection</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <b>Salaried Staff</b>: Insures 75% of salary, 30 day waiting period & 2 year benefit period
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <b>Employee Agreement Workers</b>: Insures 75% of superannuation salary, 90 day waiting period & 2 year benefit period
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <b>Casual Employees</b>: Not eligible for this cover
            </li>
          </ul>
          <p>
            <b>Investment Options</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Default Investment Option:</strong>
              MLC MySuper
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Investment returns available at:</strong>
              <a href="https://www.mlc.com.au/personal/investments/prices-and-performance/performance-and-esg-data" target="_blank">MLC MasterKey Business Super</a>
            </li>
            <li>
            <i class="ri-check-double-line"></i>
            <p><strong>Vivo Virtual Care</strong>: advice and guidance from leading health experts, anywhere, anytime.
              <a target="_blank" href="https://vivovirtualcare.com.au/">
                Learn more about the Vivo Virtual Care
              </a>
            </p>
          </li>
          </ul>
        </div>
      </div>
      `,
    pdfs: [
      {
        title: "Join the NRW Fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "docs",
        url: "NRW_New_Employee_Pack_October_2024.pdf",
      },
      {
        title: "Join the Golding Fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "docs",
        url: "Golding-New_Employee_Pack-October_2023.pdf",
      },
      {
        title: "Join the Primero Fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "docs",
        url: "Primero-New_Employee_Pack-October_2023.pdf",
      },
      {
        title: "Join the DIAB Fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "docs",
        url: "Diab-New_Employee_Pack-October_2023.pdf",
      },
      {
        title: "Product Disclosure Statement",
        description: "In-depth information on your super fund.",
        type: "docs",
        externalUrl:
          "https://www.mlc.com.au/adviser/forms-and-documents/pds/superannuation",
      },
      {
        title: "Building your Super Fact Sheet",
        description:
          "View all the different ways you can build your superannuation savings.",
        type: "docs",
        url: "Gallagher-Building_Your_Super_Factsheet-2024-2025.pdf",
      },
      {
        title: "ATO Rollover Form",
        description:
          "Do you need to rollover your previous super fund to your new super fund?",
        type: "docs",
        externalUrl:
          "https://www.ato.gov.au/Forms/Request-for-rollover-of-whole-balance-of-super-benefits-between-funds---Instructions/#Howtogettheform",
      },
      {
        title: "MLC Forms",
        description: "Find the form you need on the MLC website.",
        type: "docs",
        externalUrl:
          "https://www.mlc.com.au/personal/forms-and-documents/forms/superannuation",
      },
    ],
    optIn: "MKBS_Choose_ Insurance_form_PMIF(M153909-0421).pdf",
    teamMembers: [
      {
        teamMemberPic: "sharron.jpg",
        name: "Sharron Wharton-Street",
        phoneHref: "0862508493",
        phone: "08 6250 8493",
        email: "Sharron_WhartonStreet@ajg.com.au",
        social: "https://www.linkedin.com/in/sharron-wharton-street-801026161/",
      },
      {
        teamMemberPic: "phil.png",
        name: "Phil Mills",
        phoneHref: "0862508399",
        phone: "08 6250 8399",
        email: "phil_mills@ajg.com.au",
        social: "https://au.linkedin.com/pub/philip-mills/67/701/268",
      },
      {
        teamMemberPic: "anthony.png",
        name: "Anthony Warman",
        phoneHref: "0862508308",
        phone: "08 6250 8308",
        email: "anthony_warman@ajg.com.au",
        social: "http://au.linkedin.com/pub/anthony-warman/7b/53a/75a",
      },
      {
        teamMemberPic: "natasha.png",
        name: "Natasha Van",
        phoneHref: "1300557782",
        phone: "1300 557 782",
        email: "super-service@ajg.com.au",
      },
    ],
    footerClientName: "NRW Holdings",
  },

  superscore: false,

  analytics: {
    gacode: "",
    gaprod: "",
  },
};
